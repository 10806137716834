import React, { useState, useEffect } from "react";
import { myFirebase } from "utils/firebase";
import firebase from "firebase";
// import { useFormik } from 'formik'
import * as Yup from "yup";
import request from "request";
import otpGenerator from "otp-generator";
import { ClickAwayListener, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useFormik, FieldArray, Form, Formik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { getUser } from "components/auth";
import { keyframes } from "styled-components";

const filter = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

const InviteCard = (props) => {
  const bucketDocRef = myFirebase.firestore().collection("buckets");
  const [invited, setInvited] = useState(false);
  const [duplicate, setDuplicate] = useState(false);

  const { uid } = getUser();
  const contactRef = firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("contacts");
  const [open, toggleOpen] = React.useState(false);
  const [contactList, setContactList] = React.useState([]);
  const [refreshKey, setRefreshKey] = React.useState(0); //adding a separate state to able to refresh when new contact is added.
  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    email: "",
  });

  const posts = [];
  const useCollaborators = () => {
    const [collaborators, setCollaborators] = useState([]);
    useEffect(() => {
      bucketDocRef.doc(props.bucket.id).onSnapshot(
        (snapshot) => {
          const collaborators = snapshot.data().collaborators.verified;
          setCollaborators(collaborators);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );

      contactRef.get().then((snapshot) => {
        snapshot.forEach((doc) =>
          posts.push({
            email: doc.get("email"),
            name: doc.get("name"),
          })
        );
        setContactList(posts);
      });
    }, [refreshKey]);

    return collaborators;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      collaboratorName: "",
      senderName: props.displayName,
      dateInvited: Date.now(),
      bucketId: props.id,
      data: [],
      otp: otpGenerator.generate(4, {
        alphabets: false,
        upperCase: false,
        specialChars: false,
      }),
    },
    // validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const filteredContacts = values.data.filter(function (val) {
        return contactList.indexOf(val) == -1;
      });

      console.log(filteredContacts);

      if (filteredContacts.length !== 0) {
        storeNewContacts(filteredContacts);
      }

      Object.keys(values.data).forEach(function (key) {
        setSubmitting(true);
        Object.assign(values, { email: values.data[key].email });
        Object.assign(values, { name: values.data[key].name });
        if (addCollaborator(values)) {
          resetForm();
          if (sendData(values)) {
            setInvited(true);
          }
        }
      });
    },
  });

  const sendData = async (data) => {
    var options = {
      method: "POST",
      url: "https://us-central1-bucket-wishes.cloudfunctions.net/sendCollaboratorInvite",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      form: {
        senderName: data.senderName,
        email: data.email,
        recipientName: props.bucket.recipient ? props.bucket.recipient.name : 'No recipient set yet',
        collaboratorName: data.collaboratorName,
        bucketId: data.bucketId,
        inviteCode: data.otp
      }
    };
    request(options, function (error, response) {
      if (error) throw new Error(error);
    });
  };

  const addCollaborator = (data) => {
    if (checkForDuplicates(data.email)) {
      setDuplicate(true);
      return false;
    } else {
      const FieldValue = firebase.firestore.FieldValue;
      bucketDocRef.doc(props.id).update({
        "collaborators.pending": FieldValue.arrayUnion(data.email),
        otps: FieldValue.arrayUnion(data.otp),
      });
      return true;
    }
  };

  const collaborators = useCollaborators();

  const checkForDuplicates = (email) => {
    if (collaborators.indexOf(email) >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleClose = () => {
    setDialogValue({
      name: "",
      email: "",
    });

    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    formik.setFieldValue("data", [
      ...formik.values.data,
      {
        name: dialogValue.name,
        email: dialogValue.email,
      },
    ]);

    setContactList([
      ...contactList,
      {
        name: dialogValue.name,
        email: dialogValue.email,
      },
    ]);
    handleClose();
  };

  const storeNewContacts = (data) => {
    for (var i = 0; i < data.length; ++i)
      if (data[i] !== undefined) {
        // Upload form data to firebase
        contactRef.add(data[i]).then((ref) => {
          console.log("Report submitted with id", ref.id);
        });
      }
  };

  return (
    <div className="overlay">
      <div className="col-md-6 mx-auto">
        <ClickAwayListener onClickAway={props.handleClose}>
          <div className="create-bucket-card text-center p-5">
            <div className="close-button" onClick={props.handleClose}>
              <Close />
            </div>
            <form onSubmit={formik.handleSubmit}>
              {!invited && (
                <>
                  <Typography variant="body1" color="primary" className="w-5">
                    Invite friends and family to add wishes to this bucket
                  </Typography>
                  <div className="mx-auto">
                    <Autocomplete
                      multiple // for having mutilple select
                      value={formik.values.data}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                              name: newValue,
                              email: "",
                            });
                          });
                        } else if (
                          newValue.slice(-1)[0] &&
                          newValue.slice(-1)[0].inputValue
                        ) {
                          toggleOpen(true);
                          setDialogValue({
                            name: newValue.slice(-1)[0].inputValue,
                            email: "",
                          });
                        } else {
                          formik.setFieldValue("data", newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const isExisting = options.some(
                          (option) => params.inputValue === option.name
                        );
                        if (params.inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      freeSolo
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-dialog-demo"
                      options={contactList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(option) => option.name}
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2em",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select their name"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>

                  {duplicate && (
                    <div className="text-danger">
                      That person already has access to this bucket
                    </div>
                  )}
                  <div className="mt-4">
                    <button
                      className="bw-button"
                      type="submit"
                      // disabled={formik.errors.email ? true : false}
                    >
                      Send Invite
                    </button>
                  </div>
                </>
              )}
              {invited && (
                <>
                  <div className="text-success">
                    <Typography variant="h5">Invite Sent</Typography>
                  </div>
                  <div className="mt-4">
                    <button
                      className="bw-button"
                      onClick={() => {
                        setInvited(false);
                      }}
                    >
                      Invite Someone Else
                    </button>
                    <button className="bw-button" onClick={props.handleClose}>
                      Close
                    </button>
                  </div>
                </>
              )}
            </form>

            <Dialog open={open} onClose={handleClose}>
              <form onSubmit={handleSubmit}>
                <DialogTitle>Add a new collaborator</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Is a contact missing from your list? Please, add them!
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={dialogValue.name}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        name: event.target.value,
                      })
                    }
                    label="name"
                    type="text"
                    variant="standard"
                  />
                  <TextField
                    margin="dense"
                    id="name"
                    value={dialogValue.email}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        email: event.target.value,
                      })
                    }
                    label="email"
                    type="email"
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit">Add</Button>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default InviteCard;
