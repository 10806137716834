import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { myFirebase } from 'utils/firebase'
import Moment from 'react-moment'

const Reports = () => {
  const reportRef = myFirebase.firestore().collection('reports')

  // Fetch all report entries
  const useReport = () => {
    const [report, setReport] = useState([])

    useEffect(() => {
      reportRef.orderBy('createdAt', 'desc').onSnapshot(
        (snapshot) => {
          const newReport = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))

          setReport(newReport)
        },
        (err) => {
          console.log(`Encountered error: ${err}`)
        }
      )
    }, [])

    return report
  }
  const userReport = useReport()
  return (
    <div className='row mt-5'>
      <div className='col-12 text-center mt-4'>
        <Typography variant='h4'>Reports</Typography>
      </div>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Message</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {userReport.map((item) => (
            <tr>
              <td>
                <Typography variant='body2'>
                  {`${item.message.substring(0, 100)}...`}
                </Typography>
              </td>
              <td>
                <Typography variant='body2'>
                  <Moment format='MM/DD/YYYY'>{item.date}</Moment>
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Reports
