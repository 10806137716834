import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { myFirebase } from 'utils/firebase'
import Moment from 'react-moment'

const Feedback = () => {
  const feedbackRef = myFirebase.firestore().collection('feedback')

  // Fetch all feedback entries
  const useFeedback = () => {
    const [feedback, setFeedback] = useState([])

    useEffect(() => {
      feedbackRef.orderBy('createdAt', 'desc').onSnapshot(
        (snapshot) => {
          const newFeedback = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))

          setFeedback(newFeedback)
        },
        (err) => {
          console.log(`Encountered error: ${err}`)
        }
      )
    }, [])

    return feedback
  }
  const userFeedback = useFeedback()
  return (
    <div className='row mt-5'>
      <div className='col-12 text-center mt-4'>
        <Typography variant='h4'>Feedback</Typography>
      </div>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {userFeedback.map((item) => (
            <tr>
              <td>
                <Typography variant='body2'>{item.name}</Typography>
              </td>
              <td>
                <Typography variant='body2'>{item.email}</Typography>
              </td>
              <td>
                <Typography variant='body2'>
                  {`${item.message.substring(0, 100)} ...`}
                </Typography>
              </td>
              <td>
                <Typography variant='body2'>
                  <Moment format='MM/DD/YYYY'>{item.date}</Moment>
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Feedback
