import React, { useState, useEffect } from 'react'
import { myFirebase } from 'utils/firebase'
import { myFirebase as firebase } from 'utils/firebase';
import { getUser } from 'components/auth'
import Bucket from 'components/ui/bucket'
import BucketOpen from 'components/ui/bucketOpen'
import InviteCard from 'components/ui/invite'
import SendBucketCard from 'components/ui/sendBucketCard'
import request from 'request'
import PopupAlert from 'components/ui/popupAlert'
import AddRecipient from 'components/ui/addRecipient'
import { Typography, Button } from '@material-ui/core'
import ConfirmDialog from 'components/ui/confirmDialog'

const Admin = (props) => {
  const bucketDocRef = myFirebase.firestore().collection('buckets')
  const { displayName, uid, email } = getUser()

  const useDefaultBuckets = () => {
    const [defaultBuckets, setDefaultBuckets] = useState([])

    useEffect(() => {
      bucketDocRef
        .where('restricted', '==', true)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          (snapshot) => {
            const newBuckets = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data()
            }))

            setDefaultBuckets(newBuckets)
          },
          (err) => {
            console.log(`Encountered error: ${err}`)
          }
        )
    }, [])
    return defaultBuckets
  }

  const useBuckets = () => {
    const [buckets, setBuckets] = useState([])

    // fetch all buckets inside useEffect
    useEffect(() => {
      bucketDocRef.orderBy('createdAt', 'desc').onSnapshot(
        (snapshot) => {
          const newBuckets = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))

          setBuckets(newBuckets)
        },
        (err) => {
          console.log(`Encountered error: ${err}`)
        }
      )
    }, [])

    return buckets
  }

  const buckets = useBuckets()
  const restrictedBuckets = useDefaultBuckets()

  const [openBucket, setOpenBucket] = useState(false)
  const [openInviteCard, setOpenInviteCard] = useState(false)
  const [openAddRecipientCard, setOpenAddRecipientCard] = useState(false)
  const [openSendBucketCard, setOpenSendBucketCard] = useState(false)
  const [activeBucket, setActiveBucket] = useState(null)
  const [openPreview, setOpenPreview] = useState(false)
  const [shared, setShared] = useState(0)
  const [previewData, setPreviewData] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('') 
  const [bucketIdToDelete, setBucketIdToDelete] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  
  const handleOpenBucket = () => {
    setOpenBucket(true)
  }

  const handleCloseBucket = () => {
    setOpenBucket(false)
  }

  const handleClosePreview = () => {
    setShared(0)
    setOpenPreview(false)
  }

  const handleOpenInviteCard = () => {
    setOpenInviteCard(true)
  }

  const handleCloseInviteCard = () => {
    setOpenInviteCard(false)
  }

  const handleOpenAddRecipientCard = () => {
    setOpenAddRecipientCard(true)
  }

  const handleCloseAddRecipientCard = () => {
    setOpenAddRecipientCard(false)
  }

  const handleOpenSendBucketCard = () => {
    setOpenSendBucketCard(true)
  }

  const handleCloseSendBucketCard = () => {
    setOpenSendBucketCard(false)
  }

  const handleOpenPreview = (data) => {
    setPreviewData(data)
    setOpenPreview(true)
  }

  const handleSetActiveBucket = (bucket) => {
    setActiveBucket(bucket)
  }

 
  const postData = async (data = previewData) => {
    var options = {
      method: 'POST',
      url: 'https://us-central1-bucket-wishes.cloudfunctions.net/sendEmail',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      form: {
        name: data.name,
        email: data.email,
        bucketUrl: data.bucketUrl,
        senderName: displayName
      }
    }
    request(options, function (error, response) {
      if (error) throw new Error(error)
      bucketDocRef.doc(activeBucket.id).update({
        sent: true
      })
      setShared(1)
    })
  }

  const handleShowAlert = () => {
    setShowAlert(true)
  }

  const handleShowDeleteAlert = () => {
    setShowDeleteAlert(true)
  }

  const handleSetAlertMessage = (message) => {
    setAlertMessage(message)
  }

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true)
  }

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false)
  }


  const handleHideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowAlert(false)
  }


  const handleHideDeleteAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowDeleteAlert(false)
  }


  const handleDelete = () => {

    bucketDocRef.doc(bucketIdToDelete).delete()
    handleCloseConfirmDialog()
    handleShowDeleteAlert()
  }

  return (
    <>
      <div>

      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          handleClose={handleCloseConfirmDialog}
          message='Are you sure you want to delete this bucket?'
          action={handleDelete}
        />
      )}

        {showAlert && (
          <PopupAlert
            open={showAlert}
            severity='error'
            handleHideAlert={handleHideAlert}
            message={alertMessage}
          />
        )}


      {showDeleteAlert && (
        <PopupAlert
          open={showDeleteAlert}
          severity='error'
          handleHideAlert={handleHideDeleteAlert}
          message='Bucket Deleted!'
        />
      )}

        {openBucket && (
          <BucketOpen
            email={email}
            uid={uid}
            displayName={displayName}
            bucket={activeBucket}
            handleClose={handleCloseBucket}
            handleOpenInviteCard={handleOpenInviteCard}
            handleOpenSendBucketCard={handleOpenSendBucketCard}
            handleOpenAddRecipientCard={handleOpenAddRecipientCard}
            handleSetAlertMessage={handleSetAlertMessage}
            handleShowAlert={handleShowAlert}
            shared={shared}
            superuser={true}
          />
        )}
        {openPreview && (
          <BucketOpen
            preview={true}
            email={email}
            uid={uid}
            displayName={displayName}
            bucket={activeBucket}
            handleClose={handleClosePreview}
            shared={shared}
            setShared={setShared}
            submit={postData}
          />
        )}

        {openInviteCard && (
          <InviteCard
            email={email}
            uid={uid}
            displayName={displayName}
            id={activeBucket.id}
            bucket={activeBucket}
            handleClose={handleCloseInviteCard}
          />
        )}
        {openAddRecipientCard && (
          <AddRecipient
            email={email}
            uid={uid}
            displayName={displayName}
            id={activeBucket.id}
            handleClose={handleCloseAddRecipientCard}
            handleOpenInviteCard={handleOpenInviteCard}
          />
        )}
        {openSendBucketCard && (
          <SendBucketCard
            email={email}
            uid={uid}
            displayName={displayName}
            id={activeBucket.id}
            bucket={activeBucket}
            handleClose={handleCloseSendBucketCard}
            openPreview={openPreview}
            handleOpenPreview={handleOpenPreview}
          />
        )}

        {(buckets || restrictedBuckets) && (
          <div className='row mt-5' id='buckets-area'>
            <div className='table-responsive container mt-5'>
              <table className='table table-hover table-striped'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Author</th>
                  </tr>
                </thead>
                <tbody>
                  {buckets.map((bucket, count) => (
                    <tr key={count}>
                      <td>
                        <span
                          onClick={() => {
                            setActiveBucket(bucket)
                            handleOpenBucket()
                          }}
                          className='w-5 c-pointer text-link'
                        >
                          {bucket.title}
                        </span>
                      </td>
                      <td>{bucket.authorName}</td>
                      <td>
                        {/* <Button variant='text' color='primary' className='text-info'>Open</Button> */}
                        <Button
                          variant='text'
                          color='primary'
                          className='text-danger'
                          onClick={() => {
                            setBucketIdToDelete(bucket.id)
                            handleOpenConfirmDialog()
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Admin
