import React from "react";
import { useForm, ValidationError } from "@formspree/react";

import { Close } from "@material-ui/icons";
import { Typography } from "@material-ui/core";

function ContactForm() {
  const [state, handleSubmit] = useForm("xrgrpknl");

  const [openInviteCard, setOpenInviteCard] = React.useState(true);
  const [openSuccessCard, setOpenSuccessCard] = React.useState(true);

  if (state.succeeded) {
    return (
      <>
        {openSuccessCard && (
          <div className="overlay">
            <div className="col-md-6 mx-auto">
              <div className="create-bucket-card  p-5">
                <div
                  className="close-button"
                  onClick={() => {
                    setOpenSuccessCard(false);
                  }}
                >
                  <Close />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    padding: "90px",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="primary"
                    className="w-5 mt-2"
                  >
                    <h4>
                      Thanks for reaching out we will get back to you as soon as
                      possible!
                    </h4>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {openInviteCard && (
        <div className="overlay">
          <div className="col-md-6 mx-auto">
            <div className="create-bucket-card  p-5">
              <div
                className="close-button"
                onClick={() => {
                  setOpenInviteCard(false);
                }}
              >
                <Close />
              </div>

              <Typography
                variant="body1"
                color="primary"
                className="w-5"
                style={{
                  display: "flex",
                  flex: 1,
                  alignitems: "center",
                  justifyContent: "center",
                }}
              >
                Contact us
              </Typography>
              <div
                style={{
                  borderRadius: "5px",
                  paddingLeft: "100px",
                  paddingRight: "160px",
                  paddingBottom: "20px",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Typography
                    variant="body1"
                    color="primary"
                    className="w-5 mt-2"
                  >
                    <label htmlFor="email">Email Address</label>
                  </Typography>

                  <input
                    style={{
                      width: "100%",
                      padding: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      marginTop: "6px",
                      marginBottom: "16px",
                      resize: "vertical",
                    }}
                    id="email"
                    type="email"
                    name="email"
                    required="Please fill out this feild"
                  />

                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                  <Typography
                    variant="body1"
                    color="primary"
                    className="w-5 mt-2"
                  >
                    <label for="description">Subject</label>
                  </Typography>

                  <input
                    style={{
                      width: "100%",
                      padding: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      marginTop: "6px",
                      marginBottom: "16px",
                      resize: "vertical",
                    }}
                    id="subject"
                    name="subject"
                    required="Please fill out this feild"
                  />

                  <ValidationError
                    prefix="Subject"
                    field="subject"
                    errors={state.errors}
                  />
                  <Typography
                    variant="body1"
                    color="primary"
                    className="w-5 mt-2"
                  >
                    <label for="message">Message</label>
                  </Typography>

                  <textarea
                    style={{
                      width: "100%",
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      marginTop: "6px",
                      marginBottom: "16px",
                      resize: "vertical",
                    }}
                    id="message"
                    name="message"
                    required="Please fill out this feild"
                  />

                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                  <button
                    type="submit"
                    style={{ padding: "12px 20px", borderRadius: "4px" }}
                    disabled={state.submitting}
                    class="bw-button"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Form() {
  return <ContactForm />;
}
export default Form;
