import React, { useState, useEffect } from 'react'
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from 'firebase';
import { getUser } from "components/auth";


const filter = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.name,
});


export default function FreeSoloCreateOption() {
  const [value, setValue] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    name: '',
    email: '',
  });
  const [contactData, setContactData] = React.useState([]);
  const { uid } = getUser();
  const contactRef = firebase.firestore().collection("users").doc(uid).collection("contacts");

  const storeNewContacts = (data) => {
    // Upload form data to firebase
    contactRef.add(data).then((ref) => {
      console.log("Report submitted with id", ref.id);
    });
  };
 

  const handleClose = () => {
    setDialogValue({
      name: '',
      email: '',
    });
    toggleOpen(false);
  }

 
  const handleSubmit = (event) => {
    event.preventDefault();
    setValue([
      ...value,
      {
        name: dialogValue.name,
        email:dialogValue.email
        
      }
     
    ]);


    setContactData([
      ...contactData,
      {
        name: dialogValue.name,
        email:dialogValue.email
        
      }
     
    ]);
    handleClose();
  };
  const contactsArray = [];

  useEffect(() => {
   
    contactRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) =>
        contactsArray.push({
            email: doc.get("email"),
            name: doc.get("name"),
          })
        );
        setContactData(contactsArray);
      });
  }, [])
   
  const handleFormSubmit = (event) => {
    event.preventDefault();
   

const array1 = value.filter(function(val) {
  return contactData.indexOf(val) == -1;
});
  
    console.log(array1)
    const allNewContacts = Object.assign({}, ...array1);
    storeNewContacts({...allNewContacts})
    console.log(value);
  };


  return (
    <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
      <form onSubmit={handleFormSubmit}>
    <Autocomplete
      multiple    // for having mutilple select
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            toggleOpen(true);
            setDialogValue({
                      name: newValue,
                      email: '',
                    });
          });
        } else if (
          newValue.slice(-1)[0] &&
          newValue.slice(-1)[0].inputValue
        ) {
          toggleOpen(true);
          setDialogValue({
            name: newValue.slice(-1)[0].inputValue,
            email: '',
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const isExisting = options.some(
          (option) => params.inputValue === option.name
        );
        if (params.inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`
          });
        }

        return filtered;
      }}
      selectOnFocus
      freeSolo
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-dialog-demo"
      options={contactData}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Free solo with text demo"
          variant="outlined"
        />
      )}
    />
      <Button type="submit">Submit</Button>
</form>

    <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new film</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any  contact in our list? Please, add it!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="name"
              type="text"
              variant="standard"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.email}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  email: event.target.value,
                })
              }
              label="email"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
