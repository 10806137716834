import React, { useState, useEffect } from 'react'
import { Typography,ClickAwayListener } from '@material-ui/core'
import { myFirebase as firebase } from 'utils/firebase'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import { Close as CloseIcon } from '@material-ui/icons'
import * as Yup from 'yup'
import ConfirmDialog from 'components/ui/confirmDialog'
import PopupAlert from 'components/ui/popupAlert'
import { getUser } from "components/auth";


const Contacts = () => {
  const { uid } = getUser();
  const contactRef = firebase.firestore().collection("users").doc(uid).collection("contacts");
  const [edit, setEdit] = useState(false)
  const [contacts, setContacts] = useState([])
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [contactIdToDelete, setContactIdToDelete] = useState(null)
  const [contactIdToEdit, setContactIdToEdit] = useState(null)
  const [editValue, setEditValue] = useState("")
  const [editNameValue, setEditNameValue] = useState("")

  const useContacts = () => {

    useEffect(() => {
      contactRef.onSnapshot(
        (snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))

          setContacts(data)
          
        },
        (err) => {
          console.log(`Encountered error: ${err}`)
        }
      )
    }, [])

    return contacts
  }

  const contactData = useContacts()
  

  const editContact = (data) => {
    contactRef
      .doc(contactIdToEdit)
      .update(data)
      .then(() => {
        console.log('Document updated')
      })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please Enter your name'),
    email: Yup.string().required('Please Enter your email')
  })

  const formik = useFormik({
    initialValues: {
      email: editValue,
      name: editNameValue,  
    },
    enableReinitialize:true,
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      editContact(values)
      resetForm()
      setEdit(false)

    }
  })


  const handleClose =()=>{
    setEdit(false)  
  }

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true)
  }

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false)
  }


  const handleShowAlert = () => {
    setShowAlert(true)
  }


  const handleHideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowAlert(false)
  }

  const handleDelete = () => {

    contactRef.doc(contactIdToDelete).delete()
    handleCloseConfirmDialog()
    handleShowAlert()
  }

  return (
      <>
      
      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          handleClose={handleCloseConfirmDialog}
          message='Are you sure you want to delete this contact?'
          action={handleDelete}
        />
      )}


{showAlert && (
        <PopupAlert
          open={showAlert}
          severity='error'
          handleHideAlert={handleHideAlert}
          message='Contact Deleted!'
        />
      )}



      {edit && (
      <div className='overlay'>
      <div className='col-md-6 mx-auto'>
        <ClickAwayListener onClickAway={handleClose}>
          <form onSubmit={formik.handleSubmit}>
            <div className='create-bucket-card p-3 text-center'>
              <div className='close-button' onClick={handleClose}>
                <CloseIcon />
              </div>

              <div className='mt-3'>
                <Typography variant='subtitle'>
                  Edit Contact
                </Typography>
              </div>

              {/* Form Group Name */}
              <div>
                <Typography variant='body1' color='primary' className='w-5'>
                  Email
                </Typography>
                <div>
                  <input
                    type='email'
                    id='email'
                    name='email'
                    className='mb-1 custom-font'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    required
                  />
                </div>
                
              </div> 
              <div>
                <Typography
                  variant='body1'
                  color='primary'
                  className='w-5 mt-2'
                >
                  Name
                </Typography>
                <div>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    className='mb-1 custom-font'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    required
                  />
                </div>
                
              </div>
             <div className='mt-4'>
                <button className='bw-button' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ClickAwayListener>
      </div>
    </div>
      
    )}   

    <div className='row mt-5'>
      <div className='col-12 text-center mt-4'>
        <Typography variant='h4'>Contacts</Typography>
      </div>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {contactData.map((item) => (
            
            <tr>
              <td>
                <Typography variant='body2'>
                  {`${item.email}`}
                </Typography>
              </td>
              <td>
               <Typography variant='body2'>
                  {`${item.name}`}
                </Typography>
              </td>
              <td>
               <Button
                 variant='text'
                 color='primary'
                onClick={() => {
                    setContactIdToEdit(item.id)
                    setEditValue(item.email)
                    setEditNameValue(item.name)
                    setEdit(true)
                  }}
                >
                Edit
                </Button>
                </td>

                <td>
               <Button
                 variant='text'
                 color='primary'
                 className='text-danger'
                 onClick={() => {
                  setContactIdToDelete(item.id)
                  handleOpenConfirmDialog()
                }}
                >
                Delete
                </Button>
                </td>
                
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default Contacts
