import React, { useState } from 'react'
import { ClickAwayListener, Fab, Typography } from '@material-ui/core'
import { myFirebase } from 'utils/firebase'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Close as CloseIcon,
  Feedback as FeedbackIcon
} from '@material-ui/icons'

// A form to collect user feedback from bucket wishes
const FeedbackForm = (props) => {
  const feedBackRef = myFirebase.firestore().collection('feedback')

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please Enter your name'),
    message: Yup.string().required('Please enter a message'),
    email: Yup.string().required('Please Enter your email')
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      createdAt: Date.now()
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      createFeedbackEntry(values)
      resetForm()
      setSubmitting(false)
      handleClose()
    }
  })

  const createFeedbackEntry = (data) => {
    // Upload form data to firebase
    feedBackRef.add(data).then((ref) => {
      console.log('Feedback submitted with id', ref.id)
    })
  }

  return (
    <>
      {!open && (
        <div>
          <Fab
            className='feedback-button'
            variant='extended'
            onClick={handleOpen}
            color='secondary'
          >
            <FeedbackIcon />
            Leave Feedback
          </Fab>
        </div>
      )}
      {open && (
        <div className='overlay'>
          <div className='col-md-6 mx-auto'>
            <ClickAwayListener onClickAway={props.handleClose}>
              <form onSubmit={formik.handleSubmit}>
                <div className='create-bucket-card p-3 text-center'>
                  <div className='close-button' onClick={handleClose}>
                    <CloseIcon />
                  </div>

                  <div className='mt-3'>
                    <Typography variant='subtitle'>
                      Share your thoughts about bucket wishes with us
                    </Typography>
                  </div>

                  {/* Form Group Name */}
                  <div>
                    <Typography
                      variant='body1'
                      color='primary'
                      className='w-5 mt-2'
                    >
                      Name
                    </Typography>
                    <div>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='mb-1 custom-font'
                        onBlur={formik.handleBlur}
                        onFocus={props.handleFocus}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    </div>
                    <div className='text-danger'>
                      {formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : null}
                    </div>
                  </div>

                  {/* Form Group Email */}
                  <div>
                    <Typography variant='body1' color='primary' className='w-5'>
                      Email
                    </Typography>
                    <div>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='mb-1 custom-font'
                        onBlur={formik.handleBlur}
                        onFocus={props.handleFocus}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                    </div>
                    <div className='text-danger'>
                      {formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null}
                    </div>
                  </div>

                  {/* Form Group Message */}
                  <div>
                    <Typography variant='body1' color='primary' className='w-5'>
                      Message
                    </Typography>
                    <div>
                      <textarea
                        id='message'
                        name='message'
                        className='mb-1 custom-font'
                        onBlur={formik.handleBlur}
                        onFocus={props.handleFocus}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                      ></textarea>
                    </div>
                    <div className='text-danger'>
                      {formik.touched.message && formik.errors.message
                        ? formik.errors.message
                        : null}
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className='mt-4'>
                    <button className='bw-button' type='submit'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </ClickAwayListener>
          </div>
        </div>
      )}
    </>
  )
}

export default FeedbackForm
