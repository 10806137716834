import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { myFirebase } from 'utils/firebase'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Topbar from 'components/ui/topbar'
import Drawer from 'components/ui/drawer'

import About from 'pages/about'
import Home from 'pages/home'
import Donate from 'pages/donate'
import Help from 'pages/help'
import Buckets from 'pages/admin'
import SharedBucket from 'pages/sharedBucket'
import UserFeedback from 'pages/feedback'
import Reports from 'pages/reports'
import Feedback from 'react-feedback-popup'
import FeedbackForm from 'components/ui/feedbackForm'
import Contacts  from 'pages/contacts'
import DropDown from 'pages/check'

const Wrapper = (props) => {
  const feedBackRef = myFirebase.firestore().collection('feedback')
  const [openDrawer, setOpenDrawer] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please Enter your name'),
    message: Yup.string().required('Please enter a message'),
    email: Yup.string().required('Please Enter your email')
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      createdAt: Date.now()
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      createFeedbackEntry(values)
      resetForm()
      setSubmitting(false)
    }
  })

  const createFeedbackEntry = (data) => {
    // Upload form data to firebase
    feedBackRef.add(data).then((ref) => {
      console.log('Feedback submitted')
    })
  }

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  return (
    <div className='container main'>
      <Topbar handleToggleDrawer={handleToggleDrawer} />
      {openDrawer && <Drawer handleClose={handleCloseDrawer} {...props} />}
      <Switch>
        <Route path='/home' render={(props) => <Home {...props} />} />
        <Route path='/about' render={(props) => <About {...props} />} />
        <Route path='/donate' render={(props) => <Donate {...props} />} />
        <Route path='/help' render={(props) => <Help {...props} />} />
        <Route
          path='/feedback'
          render={(props) => <UserFeedback {...props} />}
        />
         <Route
          path='/report'
          render={(props) => <Reports {...props} />}
        />
          <Route
          path='/contacts'
          render={(props) => <Contacts {...props} />}
        />
        <Route path='/admin' render={(props) => <Buckets {...props} />} />
        <Route
          path='/bucket/:id'
          render={(props) => <SharedBucket {...props} />}
        />
        <Route exact path='/' render={(props) => <Home {...props} />} />
        <Route
          path='/check'
          render={(props) => <DropDown {...props} />}
        />
      </Switch>
      <FeedbackForm />
    </div>
  )
}

export default Wrapper
